import React from "react";

import moment from "moment";
// sweet-alert
import Swal from "sweetalert2";
import { removeCertification } from "../../utilities/certifications";

function CertificationsCard(props) {
	// Destructure Props
	const {
		userCertifications,
		isLoggedIn,
		certificationData,
	} = props;

	const deleteCertificationDialogue = (certificationId) => {
		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				cancelButton: "btn btn-outline-primary btn-lg ms-2",
				confirmButton: "btn btn-primary btn-lg",
			},
			buttonsStyling: false,
		});

		swalWithBootstrapButtons
			.fire({
				title: "Are you sure?",
				text: "You won't be able to undo this!",
				icon: "warning",
				showCancelButton: true,
				cancelButtonText: "Cancel",
				confirmButtonText: "Yes, remove it.",

				reverseButtons: false,
				showClass: {
					popup: "animate__animated animate__zoomIn",
				},
				hideClass: {
					popup: "animate__animated animate__zoomOut",
				},
			})
			.then((result) => {
				if (result.isConfirmed) {
					return removeCertification(certificationId);
				} else {
					return true;
				}
			})
			.then((deletionResponse) => {
				if (deletionResponse) {
					swalWithBootstrapButtons.fire({
						title: "Deleted!",
						text: "This certification has been removed from your profile.",
						icon: "success",
						showClass: {
							popup: "animate__animated animate__zoomIn",
						},
						hideClass: {
							popup: "animate__animated animate__zoomOut",
						},
						didClose: window.location.reload(),
					});
				} else {
					swalWithBootstrapButtons.fire({
						title: "Request Failed!",
						text: "Unable to remove this certification, please try again.",
						icon: "error",
						showClass: {
							popup: "animate__animated animate__zoomIn",
						},
						hideClass: {
							popup: "animate__animated animate__zoomOut",
						},
					});
				}
			});
	};

	return (
		<div>
			<ul className="iq-timeline ms-1">
				{userCertifications.map((certification) => {
					const certificationDetails = certificationData.find(
						(cert) => cert.id === certification.certificationId,
					);
					return (
						<li key={certification.certificationId}>
							<div className="timeline-dots border-warning"></div>
							<div className="d-flex align-items-center justify-content-between">
								<h6 className="mb-1">
									{certificationDetails.name} - {certificationDetails.title}
								</h6>
								<div className="d-flex align-items-center">
									<small>
										{moment(certification.dateCompleted).format("D MMMM YYYY")}
									</small>
									{isLoggedIn === true && (
										<div
											className="me-3 rounded bg-danger-subtle border-0 d-inline-block px-1"
											style={{ margin: "10px" }}
										>
											<span
												className="material-symbols-outlined font-size-18 align-text-bottom"
												onClick={() =>
													deleteCertificationDialogue(
														certification.certificationId,
													)
												}
											>
												close
											</span>
										</div>
									)}
								</div>
							</div>
							<div className="d-inline-block w-100">
								<p>
									{certification.schoolName} -{" "}
									{certificationDetails.description}
								</p>
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
}

export default CertificationsCard;
