import { createSlice } from "@reduxjs/toolkit";
import { initialState, defaultState } from "./state.ts";
import { auth as authInstance } from '../../config/firebaseConfig';
import { getAuth, signOut } from 'firebase/auth';
import {
  updateStorage,
  getStorage,
} from "../../utilities/setting";
import _ from "lodash";
// const DefaultSetting = defaultState.auth;

const createAuthObj = (state) => {
  return {
    saveLocal: state.saveLocal,
    storeKey: state.storeKey,
    auth: _.cloneDeep(state.auth),
  };
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const json = getStorage(state.storeKey);
      if (json === "none") state.saveLocal = "none";
      if (json !== null && json !== "none") {
        state.saveLocal = json.saveLocal;
        state.auth = json.auth;
      }
      updateStorage(state.saveLocal, state.storeKey, createAuthObj(state));
    },
    logout:  (state, action) => {
      state.auth = defaultState.auth;
      updateStorage(state.saveLocal, state.storeKey, createAuthObj(state));
      signOut(authInstance);
    },
    saveLocal: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.saveLocal = action.payload;
      }
      const authObj = {
        saveLocal: state.saveLocal,
        storeKey: state.storeKey,
        auth: _.cloneDeep(state.auth),
      };
      updateStorage(state.saveLocal, state.storeKey, authObj);
    },
    uid: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.auth.uid = action.payload;
      }
      updateStorage(state.saveLocal, state.storeKey, createAuthObj(state));
    },
    accessToken: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.auth.accessToken = action.payload;
      }
      updateStorage(state.saveLocal, state.storeKey, createAuthObj(state));
    },
    email: (state, action) => {
      if (typeof action.payload !== typeof undefined) {
        state.auth.email = action.payload;
      }
      updateStorage(state.saveLocal, state.storeKey, createAuthObj(state));
    }
  },
});

export default authSlice.reducer;
