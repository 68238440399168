import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Card } from "react-bootstrap";

function UserInformationCard(props) {
	// Destructure Props
	const { userData } = props;

	return (
		<div className="fixed-md-suggestion mb-0 mb-md-4">
			<Card>
				<div className="card-header d-flex justify-content-between border-bottom">
					<div className="header-title">
						<h4 className="card-title">About Me</h4>
					</div>
				</div>
				<Card.Body>
					<div className="ui-block-content">
						<ul className="list-unstyled ">
							<li className="mb-4">
								<h6 className="title">Intro</h6>
								<span className="text">{userData.intro}</span>
							</li>
							<li className="mb-4">
								<h6 className="title">Sailing Background</h6>
								<span className="text">{userData.sailingBackground}</span>
							</li>
							<li className="mb-4">
								<h6 className="title">Hobbies</h6>
								<span className="text">{userData.hobbies}</span>
							</li>
						</ul>
						<div className="widget w-socials">
							<h6 className="title mb-3">Other Social Networks:</h6>

							<Link
								to={userData.instagramUrl}
								className="social-item btn btn-danger d-flex align-items-center justify-content-center"
							>
								<svg
									className="me-1"
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									width="14"
									height="24"
									viewBox="0 0 448 512"
									fill="currentColor"
								>
									<title>instagram</title>
									<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
								</svg>
								Instagram
							</Link>
						</div>
					</div>
				</Card.Body>
			</Card>
			<Card>
				<div className="card-header d-flex justify-content-between border-bottom">
					<div className="header-title">
						<h4 className="card-title">Basic Information</h4>
					</div>
				</div>
				<Card.Body>
					<div className="ui-block-content">
						<ul className="list-unstyled ">
							<li className="mb-4">
								<h6 className="title">Age</h6>
								<span className="text">
									{moment().diff(moment(userData.dob), "years")}
								</span>
							</li>

							<li className="mb-4">
								<h6 className="title">Gender</h6>
								<span className="text">{userData.gender}</span>
							</li>

							<li className="mb-4">
								<h6 className="title">Interests</h6>
								<span className="text">
									{userData.interests.map((interests) => interests + ", ")}
								</span>
							</li>

							<li className="mb-4">
								<h6 className="title">Languages</h6>
								<span className="text">
									{userData.languages.map((language) => language + ", ")}
								</span>
							</li>
						</ul>
					</div>
				</Card.Body>
			</Card>
		</div>
	);
}

export default UserInformationCard;
