import { getAuth } from "firebase/auth";

import {
	getFirestore,
	addDoc,
	collection,
} from "firebase/firestore";

// export const removeSaillog = async (saillogId) => {
// 	if (typeof saillogId !== "string") {
// 		throw Error("Invalid saillogId format");
// 	}

// 	const auth = getAuth();
// 	const user = auth.currentUser;
// 	const firestore = getFirestore();
// 	const docRef = doc(firestore, "sailinglogs", user.uid);
// 	await getDoc(docRef)
// 		.then((documentSnapshot) => {
// 			let userProfile = documentSnapshot.data();
// 			userProfile.certifications = userProfile.certifications.filter(
// 				(certification) => certification.certificationId !== certificationId,
// 			);
// 			return updateDoc(docRef, userProfile);
// 		})
// 		.then((responseOfSetDocFunction) => {
// 			// console.log(responseOfSetDocFunction);
// 		})
// 		.catch((error) => {
// 			console.log("[removeCertification] ", error);
// 			return false;
// 		});
// 	return true;
// };

export const addSaillog = async (
	comment,
	startDate,
	charterCompany,
	distance,
	durationDays,
	hullType,
	ports,
	role,
) => {
	if (
		// typeof certificationId !== "string" ||
		typeof comment !== "string" ||
		typeof startDate !== "string" ||
		typeof charterCompany !== "string" ||
		typeof distance !== "string" ||
		typeof durationDays !== "string" ||
		typeof hullType !== "string" ||
		typeof role !== "string"
	) {
		throw Error("[addSaillog] Missing data or invalid data types.");
	}

	const auth = getAuth();
	const user = auth.currentUser;
	const firestore = getFirestore();

	await addDoc(collection(firestore, "sailinglogs"), {
		comment: comment,
		startDate: startDate,
		charterCompany: charterCompany,
		distance: Number(distance),
		durationDays: Number(durationDays),
		hullType: hullType,
		ports: ports,
		role: role,
		uid: user.uid,
		username: user.displayName,
		userFullName: user.displayName,
	}).catch((error) => {
		console.log("[removeCertification] ", error);
		return false;
	});

	return true;
};
