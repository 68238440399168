import { getAuth } from "firebase/auth";

import { getFirestore, updateDoc, getDoc, doc } from "firebase/firestore";

export const removeCertification = async (certificationId) => {
	if (typeof certificationId !== "string") {
		throw Error("Invalid certificationId format");
	}

	const auth = getAuth();
	const user = auth.currentUser;
	const firestore = getFirestore();
	const docRef = doc(firestore, "profiles", user.uid);
	await getDoc(docRef)
		.then((documentSnapshot) => {
			let userProfile = documentSnapshot.data();
			userProfile.certifications = userProfile.certifications.filter(
				(certification) => certification.certificationId !== certificationId,
			);
			return updateDoc(docRef, userProfile);
		})
		.then((responseOfSetDocFunction) => {
			// console.log(responseOfSetDocFunction);
		})
		.catch((error) => {
			console.log("[removeCertification] ", error);
			return false;
		});
	return true;
};

export const addCertification = async (
	certificationId,
	schoolName,
	dateCompleted,
) => {
	if (
		typeof certificationId !== "string" ||
		typeof schoolName !== "string" ||
		typeof dateCompleted !== "string"
	) {
		throw Error("[addCertification] Missing data or invalid data types.");
	}

	const auth = getAuth();
	const user = auth.currentUser;
	const firestore = getFirestore();
	const docRef = doc(firestore, "profiles", user.uid);
	await getDoc(docRef)
		.then((documentSnapshot) => {
			let userProfile = documentSnapshot.data();
			const certifications = [
				...userProfile.certifications,
				{
					certificationId: certificationId,
					schoolName: schoolName,
					dateCompleted: dateCompleted,
				},
			];
			let sortedCertifications = [...certifications].sort(
				(b, a) => Date.parse(a.dateCompleted) - Date.parse(b.dateCompleted),
			);
			userProfile.certifications = sortedCertifications;
			return updateDoc(docRef, userProfile);
		})
		.then((responseOfSetDocFunction) => {
			// console.log(responseOfSetDocFunction);
		})
		.catch((error) => {
			console.log("[removeCertification] ", error);
			return false;
		});
	return true;
};
