// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app';
import { getFirestore } from 'firebase/firestore';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB9dvp14nBY7eGG9oX-l1ln93rC3icjcjk",
  authDomain: "mysailinglog-b0192.firebaseapp.com",
  projectId: "mysailinglog-b0192",
  storageBucket: "mysailinglog-b0192.firebasestorage.app",
  messagingSenderId: "5132706945",
  appId: "1:5132706945:web:d5a40ab24b279883c19980",
  measurementId: "G-E3WW1N319E"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = getFirestore(firebase.app);
export const auth = firebase.auth();
