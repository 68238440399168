import { AuthState, AuthDefaultState } from "./interface";

// Initial Setting State
export const initialState: AuthState = {
  saveLocal: "sessionStorage",
  storeKey: "auth-react",
  auth: {
    uid: '',
    accessToken: '',
    email: ''
  },
};

// Default Setting State
export const defaultState: AuthDefaultState = {
  saveLocal: "sessionStorage",
  storeKey: "auth-react",
  auth: {
    uid: '',
    accessToken: '',
    email: ''
  },
};
