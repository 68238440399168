import { db } from "../config/firebaseConfig";
import { collection, query, where, getDocs } from "firebase/firestore";

export const getProfile = async (username) => {
	if (typeof username !== "string") {
		throw Error("Invalid username format");
	}

	const profilesCollection = collection(db, "profiles");

	// Create a query against the collection
	const q = query(profilesCollection, where("username", "==", username));

	try {
		const querySnapshot = await getDocs(q);

		if (querySnapshot.empty) {
			console.log("No matching documents for username: ", username);
			return null;
		}

		// Map the results to an array of profile data
		const profiles = querySnapshot.docs.map((doc) => ({
			...doc.data(),
			id: doc.id,
		}));
		return profiles[0];
	} catch (error) {
		console.error("Error getting documents:", error);
		throw error;
	}
};
