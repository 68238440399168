import React, { useState } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";

import moment from "moment";
import { addCertification } from "../../utilities/certifications";
import { useCertifications } from "../hooks/useCertifications";

function AddCertificationModal(props) {
	// Destructure Props
	const {
		showAddCertModal,
		handleHideAddCertModal,
		existingCerts,
		certificationData,
	} = props;
	const [validated, setValidated] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData] = useState({
		schoolName: "",
		dateCompleted: moment(new Date()).format("YYYY-MM-DD"),
		certificationId: "",
	});

	// const [certificationData] = useCertifications();

	const handleFormChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			// Handle form submission
			await addCertification(
				formData.certificationId,
				formData.schoolName,
				formData.dateCompleted,
			);
			window.location.reload();
		}
		setValidated(true);
	};

	return (
		<Modal centered show={showAddCertModal} onHide={handleHideAddCertModal}>
			<Modal.Header closeButton>
				<Modal.Title className="h5">Add Certification</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md="12">
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Form.Group className="form-group">
								<Form.Label>School Name: *</Form.Label>
								<Form.Control
									type="text"
									id="schoolName"
									name="schoolName"
									placeholder="School Name"
									required
									value={formData.schoolName}
									onChange={handleFormChange}
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label>Date Completed: *</Form.Label>
								<Form.Control
									type="date"
									id="dateCompleted"
									name="dateCompleted"
									required
									value={formData.dateCompleted}
									onChange={handleFormChange}
								/>
							</Form.Group>

							<Form.Group className="form-group">
								<Form.Label htmlFor="certificationId">
									Select ASA Certification
								</Form.Label>
								<select
									className="form-select"
									data-trigger
									name="certificationId"
									id="certificationId"
									required
									value={formData.certificationId}
									onChange={handleFormChange}
								>
									<option value=""></option>
									{certificationData.map((certification) => {
										if (existingCerts.includes(certification.id)) {
											return null;
										} else {
											return (
												<option value={certification.id} key={certification.id}>
													{certification.name} - {certification.title}
												</option>
											);
										}
									})}
								</select>
							</Form.Group>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleHideAddCertModal}>
									Close
								</Button>
								<Button variant="primary" type="submit">
									Save changes
								</Button>
							</Modal.Footer>
						</Form>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
}

export default AddCertificationModal;
