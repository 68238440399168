import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Row, Col } from "react-bootstrap";

import img3 from "../../assets/images/user/1.jpg";

import img4 from "../../assets/images/page-img/57.jpg";
import img5 from "../../assets/images/page-img/58.jpg";
import img6 from "../../assets/images/page-img/59.jpg";
import { useSailingLogsByUserId } from "../hooks/useSailingLogs";
import * as moment from "moment";

function SailingLogsCard(props) {
	// Destructure Props
	const { userId } = props;
	const [sailingLogData] = useSailingLogsByUserId(userId);
	return (
		<div>
			{sailingLogData.map((log) => {
				return (
					<div className="borderbox1 mt-3 rounded d-flex rounded" key={log.id}>
						<div className="user-img me-2">
							<img
								loading="lazy"
								src="https://media.licdn.com/dms/image/v2/D5603AQElzrvZtRpJGQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1732051984257?e=1742428800&v=beta&t=avsppKtLENbF7XNRNk0-5vZ6HqBOVpYvkxC6l5ZSGRI"
								alt="userimg"
								className="avatar-40 rounded-circle"
							/>
						</div>
						<div className="borderbox border rounded p-2">
							<div className="d-flex align-items-center justify-content-between flex-wrap gap-2">
								<div className="d-flex align-items-center">
									<h6>{log.userFullName}</h6>
									<span className="text-primary ms-1 d-flex align-items-center">
										<i className="material-symbols-outlined me-2 text-primary md-16">
											check_circle
										</i>
									</span>
									<Link to="#" className="mb-0">
										@{log.username}
									</Link>
								</div>
								<div className="d-flex align-items-center">
									<i className="material-symbols-outlined md-16">event</i>
									<span className="mx-1">
										<small>{moment(log.startDate).format("D MMMM YYYY")}</small>
									</span>
								</div>
							</div>
							<p className="mb-0">{log.comment}</p>
							<div className="d-flex align-items-center gap-3 flex-shrink-0">
								<div className="share-block d-flex align-items-center feather-icon">
									<span className="material-symbols-outlined align-text-top font-size-20">
										sailing
									</span>{" "}
									<span className="fw-medium">{log.hullType}</span>
								</div>

								<div className="share-block d-flex align-items-center feather-icon">
									<span className="material-symbols-outlined align-text-top font-size-20">
										distance
									</span>
									<span className="ms-1 fw-medium">
										{log.distance} Nautical Miles
									</span>
								</div>
								<div className="share-block d-flex align-items-center feather-icon">
									<span className="material-symbols-outlined align-text-top font-size-20">
										date_range
									</span>
									<span className="ms-1 fw-medium">
										{log.durationDays} Days
									</span>
								</div>
							</div>
							<Row className="mt-2">
								<Col lg="4" md="6" className="mt-1">
									<img
										loading="lazy"
										src={img4}
										className="img-fluid rounded"
										alt="Responsive img"
									/>
								</Col>
								<Col lg="4" md="6" className="mt-1">
									<img
										loading="lazy"
										src={img5}
										className="img-fluid rounded"
										alt="Responsive img"
									/>
								</Col>
								<Col lg="4" md="6" className="mt-1">
									<img
										loading="lazy"
										src={img6}
										className="img-fluid rounded"
										alt="Responsive img"
									/>
								</Col>
							</Row>
							<div className="d-flex flex-wrap justify-content-evenly mb-0 mt-2">
								<div className="d-flex align-items-center">
									<i className="material-symbols-outlined md-16 icon-22">
										chat_bubble_outline
									</i>
									<h6 className="ms-2">32</h6>
								</div>
								<hr className="hr-vertical" />
								<div className="d-flex align-items-center">
									<i className="material-symbols-outlined md-16 icon-22">
										sync
									</i>
									<h6 className="ms-2">54</h6>
								</div>
								<hr className="hr-vertical" />
								<div className="d-flex align-items-center">
									<i className="material-symbols-outlined md-16 icon-22">
										favorite_border
									</i>
									<h6 className="ms-2">426</h6>
								</div>
								<hr className="hr-vertical" />
								<i className="material-symbols-outlined icon-rtl md-16 icon-22">
									shortcut
								</i>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
}

export default SailingLogsCard;
