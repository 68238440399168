import React, { useState } from "react";
import { Row, Col, Modal, Button, Form } from "react-bootstrap";

import moment from "moment";
import user1 from "../../assets/images/user/1.jpg";
import { addSaillog } from "../../utilities/sailinglogs";

function AddSailingLogModal(props) {
	// Destructure Props
	const { showAddSailLogModal, handleHideAddSaiLogModal } = props;
	const [validated, setValidated] = useState(false);
	const [error, setError] = useState(null);
	const [formData, setFormData] = useState({
		comment: "",
		startDate: moment(new Date()).format("YYYY-MM-DD"),
		charterCompany: "",
		distance: null,
		durationDays: null,
		hullType: "",
		ports: ["ddd"],
		role: "",
	});

	const handleFormChange = (event) => {
		setFormData({
			...formData,
			[event.target.name]: event.target.value,
		});
	};
	const handleSubmit = async (event) => {
		event.preventDefault();
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			// Handle form submission
			// Required Fields:
			// comment,
			// startDate,
			// charterCompany,
			// description,
			// distance,
			// durationDays,
			// hullType,
			// ports,
			// role,
			await addSaillog(
				formData.comment,
				formData.startDate,
				formData.charterCompany,
				formData.distance,
				formData.durationDays,
				formData.hullType,
				formData.ports,
				formData.role,
			);
			window.location.reload();
		}
		setValidated(true);
	};

	return (
		<Modal
			centered
			show={showAddSailLogModal}
			onHide={handleHideAddSaiLogModal}
		>
			<Modal.Header closeButton>
				<Modal.Title className="h5">Add Sail Log</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col md="12">
						<Form noValidate validated={validated} onSubmit={handleSubmit}>
							<Form.Group className="d-flex align-items-center">
								<div className="user-img">
									<img
										loading="lazy"
										src={user1}
										alt="userimg"
										className="avatar-60 rounded-circle img-fluid"
									/>
								</div>
								<Form.Control
									className="form-control rounded"
									style={{ border: "none" }}
									type="text"
									id="comment"
									name="comment"
									placeholder="Tell us about your sail..."
									required
									value={formData.comment}
									onChange={handleFormChange}
								/>
							</Form.Group>
							<hr />
							<Form.Group className="form-group">
								<Form.Label>Date Started: *</Form.Label>
								<Form.Control
									type="date"
									id="startDate"
									name="startDate"
									required
									value={formData.startDate}
									onChange={handleFormChange}
								/>
							</Form.Group>
							<Form.Group className="form-group">
								<Form.Label>Charter Company: *</Form.Label>
								<Form.Control
									type="text"
									id="charterCompany"
									name="charterCompany"
									required
									value={formData.charterCompany}
									onChange={handleFormChange}
								/>
							</Form.Group>
							<Form.Group className="form-group">
								<Form.Label>Distance (nautical miles): *</Form.Label>
								<Form.Control
									type="number"
									id="distance"
									name="distance"
									required
									value={formData.distance}
									onChange={handleFormChange}
								/>
							</Form.Group>
							<Form.Group className="form-group">
								<Form.Label>Duration (days): *</Form.Label>
								<Form.Control
									type="number"
									id="durationDays"
									name="durationDays"
									required
									value={formData.durationDays}
									onChange={handleFormChange}
								/>
							</Form.Group>
							<Form.Group className="form-group">
								<Form.Label htmlFor="hullType">Hull Type</Form.Label>
								<select
									className="form-select"
									data-trigger
									name="hullType"
									id="hullType"
									required
									value={formData.hullType}
									onChange={handleFormChange}
								>
									<option value=""></option>
									{["Multi-hull", "Single-hull"].map((hulltype) => {
										return (
											<option value={hulltype} key={hulltype}>
												{hulltype}
											</option>
										);
									})}
								</select>
							</Form.Group>
							<Form.Group className="form-group">
								<Form.Label htmlFor="role">Role onboard</Form.Label>
								<select
									className="form-select"
									data-trigger
									name="role"
									id="role"
									required
									value={formData.role}
									onChange={handleFormChange}
								>
									<option value=""></option>
									{[
										"Skipper",
										"First Officer",
										"Crew",
										"Deck Hand",
										"Student",
										"Guest",
									].map((hulltype) => {
										return (
											<option value={hulltype} key={hulltype}>
												{hulltype}
											</option>
										);
									})}
								</select>
							</Form.Group>
							<Modal.Footer>
								<Button variant="secondary" onClick={handleHideAddSaiLogModal}>
									Close
								</Button>
								<Button variant="primary" type="submit">
									Save changes
								</Button>
							</Modal.Footer>
						</Form>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
}

export default AddSailingLogModal;
