import { db } from "../../config/firebaseConfig";
import {
	useQuery,
} from "@tanstack/react-query";
import { collection, query, getDocs } from "firebase/firestore";

export function useCertifications  ()  {
	const profilesCollection = collection(db, "certifications");
	// Create a query against the collection
	const q = query(profilesCollection);

	const { isPending, error, data } = useQuery({
		queryKey: ["certificationData"],
		queryFn: () =>
			getDocs(q).then((querySnapshot) =>
				querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				})),
			),
	});

    if (isPending) return [[]];

  if (error) {
    console.log('[getCertifications] An error has occurred: ' + error.message);
    return [[]];
  }
  return [data];
};

export function useGetCertificationById (certificationId) {
	const certifications = useCertifications();
	const certification = certifications.find(cert => cert.id === certificationId);
	return certification;
}
