import { db } from "../../config/firebaseConfig";
import {
	useQuery,
} from "@tanstack/react-query";
import { collection, query, getDocs, where } from "firebase/firestore";

export function useSailingLogsByUserId  (userId)  {
	let querueParam='UNDEFINED';
	if (typeof userId !== 'undefined') {
		querueParam = userId;
	}
	const profilesCollection = collection(db, "sailinglogs");
	// Create a query against the collection
	const q = query(profilesCollection, where("uid", "==", querueParam));

	const { isPending, error, data } = useQuery({
		queryKey: ["sailingLogData",{userId}],
		queryFn: () =>
			getDocs(q).then((querySnapshot) =>
				querySnapshot.docs.map((doc) => ({
					...doc.data(),
					id: doc.id,
				})),
			),
	});

    if (isPending) return [[]];

  if (error) {
    console.log('[userSailingLogs] An error has occurred: ' + error.message);
    return [[]];
  }
  return [data];
};

// export function useSailingLogsByUserId (userId) {
// 	const certifications = useSailingLogs();
// 	console.log('getCertificationById: ', certifications);
// 	const certification = certifications.find(cert => cert.id === certificationId);
// 	console.log('foudn cert:', certification);
// 	return certification;
// }
