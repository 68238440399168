import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Nav,
	Tab,
	ProgressBar,
	Button,
} from "react-bootstrap";
import Card from "../../../components/Card";

import delwinhero from "../../../assets/images/user/delwin-hero.png";
import imgpp38 from "../../../assets/images/icon/08.png";
import imgpp39 from "../../../assets/images/icon/09.png";
import imgpp40 from "../../../assets/images/icon/10.png";
import imgpp41 from "../../../assets/images/icon/11.png";
import imgpp42 from "../../../assets/images/icon/12.png";
import imgpp43 from "../../../assets/images/icon/13.png";

import { getProfile } from "../../../utilities/profile";
// Redux Selector / Action
import { useSelector } from "react-redux";
import * as AuthSelector from "../../../store/auth/selectors";
import CertificationsCard from "../../../components/sailingprofile/certificationsCard";
import AddCertificationModal from "../../../components/sailingprofile/addCertificationModal";
import { useCertifications } from "../../../components/hooks/useCertifications";
import UserInformationCard from "../../../components/sailingprofile/userInformationCard";
import SailingLogsCard from "../../../components/sailingprofile/sailingLogsCard";
import AddSailingLogModal from "../../../components/sailingprofile/addSailLogModal";

const SailingProfile = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const [userData, setUserData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [certificationData] = useCertifications();
	const [showAddCertModal, setShowAddCertModal] = useState(false);
	const handleShowAddCertModal = () => setShowAddCertModal(true);
	const handleHideAddCertModal = () => setShowAddCertModal(false);
	const [showAddSailLogModal, setShowAddSailLogModal] = useState(false);
	const handleShowAddSailLogModal = () => setShowAddSailLogModal(true);
	const handleHideAddSailLogModal = () => setShowAddSailLogModal(false);

	const isLoggedIn = useSelector(AuthSelector.isLoggedIn);

	useEffect(() => {
		const fetchUserProfile = async () => {
			try {
				setIsLoading(true);
				const username = location.pathname.slice(1);
				const profileData = await getProfile(username);
				if (profileData) {
					setUserData(profileData); // Assuming we want the first match
				} else {
					setError("Profile not found");
				}
			} catch (err) {
				setError(err.message);
				console.error("Error fetching profile:", err);
			} finally {
				setIsLoading(false);
			}
		};

		fetchUserProfile();
	}, [location.pathname]); // Re-run if the pathname changes

	if (isLoading) {
		return (
			<div className="progress mb-3">
				<ProgressBar
					animated
					striped
					now={100}
					style={{ width: "100%" }}
				></ProgressBar>
			</div>
		);
	}

	if (error) {
		navigate("/errors/error404");
		return (
			<div className="progress mb-3">
				<ProgressBar
					animated
					striped
					now={100}
					style={{ width: "100%" }}
				></ProgressBar>
			</div>
		);
	}

	if (!userData) {
		navigate("/errors/error404");
		return (
			<div className="progress mb-3">
				<ProgressBar
					animated
					striped
					now={100}
					style={{ width: "100%" }}
				></ProgressBar>
			</div>
		);
	}

	return (
		<>
			<div id="content-page" className="content-inner pt-0">
				<AddCertificationModal
					showAddCertModal={showAddCertModal}
					handleHideAddCertModal={handleHideAddCertModal}
					existingCerts={userData.certifications.map(
						(cert) => cert.certificationId,
					)}
					certificationData={certificationData}
				/>
				<AddSailingLogModal
					showAddSailLogModal={showAddSailLogModal}
					handleHideAddSaiLogModal={handleHideAddSailLogModal}
				/>

				<Container>
					<Row>
						<Col sm="12">
							<Card>
								<Card.Body className="profile-page p-0">
									<div className="profile-header">
										<div className="position-relative">
											<img
												loading="lazy"
												src={delwinhero}
												alt="profile-bg"
												className="rounded img-fluid"
											/>
											{isLoggedIn === true && (
												<ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
													<li>
														<Link to="#" className="material-symbols-outlined">
															edit
														</Link>
													</li>
												</ul>
											)}
										</div>
										<div className="user-detail text-center mb-3">
											<div className="profile-img">
												<img
													loading="lazy"
													src="https://media.licdn.com/dms/image/v2/D5603AQElzrvZtRpJGQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1732051984257?e=1742428800&v=beta&t=avsppKtLENbF7XNRNk0-5vZ6HqBOVpYvkxC6l5ZSGRI"
													u
													alt="profile-img"
													className="avatar-130 img-fluid"
												/>
											</div>
											<div className="profile-detail">
												<h3 className="">
													{userData.firstName} {userData.lastName}
												</h3>
											</div>
										</div>
										<div className="profile-info py-5 px-md-5 px-3 d-flex align-items-center justify-content-between position-relative">
											<div className="social-links">
												<ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
													<li className="text-center pe-3">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp38}
																className="img-fluid rounded"
																alt="facebook"
															/>
														</Link>
													</li>
													<li className="text-center pe-3">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp39}
																className="img-fluid rounded"
																alt="Twitter"
															/>
														</Link>
													</li>
													<li className="text-center pe-3">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp40}
																className="img-fluid rounded"
																alt="Instagram"
															/>
														</Link>
													</li>
													<li className="text-center pe-3">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp41}
																className="img-fluid rounded"
																alt="Google plus"
															/>
														</Link>
													</li>
													<li className="text-center pe-3">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp42}
																className="img-fluid rounded"
																alt="You tube"
															/>
														</Link>
													</li>
													<li className="text-center md-pe-3 pe-0">
														<Link to="#">
															<img
																loading="lazy"
																src={imgpp43}
																className="img-fluid rounded"
																alt="linkedin"
															/>
														</Link>
													</li>
												</ul>
											</div>
											<div className="social-info">
												<ul
													// className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0"
													className="social-data-block social-user-meta-list d-flex align-items-center justify-content-center list-inline p-0 m-0 gap-1"
												>
													<li className="text-center">
														<p className="mb-0">690</p>
														<h6>Trips Logged</h6>
													</li>
													<li className="text-center">
														<p className="mb-0">206</p>
														<h6>Nautical Miles</h6>
													</li>
													<li className="text-center">
														<p className="mb-0">100</p>
														<h6>MySailing Friends</h6>
													</li>
												</ul>
											</div>
										</div>
									</div>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					<Row className="mt-1">
						<Col lg="4" md="4">
							<UserInformationCard userData={userData} />
						</Col>
						<Col lg="8" md="8">
							<Tab.Container defaultActiveKey="sailinglogs">
								<Card>
									<nav className="tab-bottom-bordered ">
										<Nav
											variant="tabs"
											className="mb-0 rounded-top border-0 flex-row"
											style={{ flexDirection: "row!important" }}
										>
											<Nav.Link eventKey="sailinglogs" className="border-0">
												Sailing Log
											</Nav.Link>
											<Nav.Link
												eventKey="certifications"
												href="#"
												className="border-0"
											>
												Certifications
											</Nav.Link>
											<Nav.Link eventKey="f3" href="#" className="border-0">
												Contact
											</Nav.Link>
										</Nav>
									</nav>
									<Tab.Content
										className="card-body iq-tab-fade-up"
										style={{ "--bs-card-spacer-y": "0" }}
									>
										<Tab.Pane eventKey="certifications">
											{isLoggedIn === true && (
												<Button
													variant="primary"
													className="d-block w-100 mt-3"
													onClick={handleShowAddCertModal}
												>
													Add Certification
												</Button>
											)}
											<CertificationsCard
												userCertifications={userData.certifications}
												isLoggedIn={isLoggedIn}
												certificationData={certificationData}
											/>
										</Tab.Pane>
										<Tab.Pane eventKey="sailinglogs">
											{isLoggedIn === true && (
												<Button
													variant="primary"
													className="d-block w-100 mt-2 mb-2"
													onClick={handleShowAddSailLogModal}
												>
													Add Sailing Log
												</Button>
											)}
											<SailingLogsCard userId={userData.uid} />
											{/* <CertificationsCard
												userCertifications={userData.certifications}
												isLoggedIn={isLoggedIn}
												handleShowAddCertModal={handleShowAddCertModal}
												certificationData={certificationData}
											/> */}
										</Tab.Pane>{" "}
									</Tab.Content>
								</Card>
							</Tab.Container>
							{/* </Card.Body> */}
							{/* </Card> */}
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
};
export default SailingProfile;
